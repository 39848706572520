<template>
<v-card >
    <v-app-bar
      color="#71afb7"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title>Trouve Ton vélo</v-toolbar-title>

      <v-spacer></v-spacer>
       <v-btn @click="searchCard" icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

     
    </v-app-bar>

    <v-navigation-drawer
      color="#71afb7"
      v-model="drawer"
      fixed
      temporary
      height=100%
       class="white--text"
    >
      <v-list
        nav
        dense
       
      >
        <v-list-item-group
          v-model="group"
          active-class="text--accent-4"
        >
        <div>
          <div>
            <h1 class="white--text">Menu velo</h1>
          </div>
          <v-list-item v-for="item in items" :key="item.title" :to="item.to" link> 
            <v-list-item-icon>
              <v-icon class="white--text">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{item.title}}</v-list-item-title>
          </v-list-item>
         </div>         
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      items: [
      { title: "Home", icon: "mdi-home-circle", to: "/" },
      { title: "Carte", icon: "mdi-earth", to: "/map" },
      { title: "Aide", icon: "mdi-help-box", to: "/help" },
      { title: "Recherche sur la carte", icon: "mdi-help-box", to: "/confirmationVelo" },
    ],
      drawer: false,
      group: null,
    }),
    methods:{
      searchCard(){

        this.$router.push('./')
      }
    }
  }
</script>
<style scoped>
    .white--text ,v-list-item{
      color:white!important
    }
</style>