import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dataVelo:[],
    addressGeolocate:[]
  },
  mutations: {
    GET_DATA_VELO(state,data){
      state.dataVelo = data
    },
    GET_ADRESS_GEOLOCATE(state,data){
      console.log("geolocate")
      state.addressGeolocate = data
    }
  },
  actions: {
    getDataVelo({commit},data){
      commit("GET_DATA_VELO",data)
    }
  },
  
})
