<template>
  <div class="backgroundHome">
    <v-row class="opacity"></v-row>
    <v-row  class="main" justify="center">
      <v-col>
        <h1>Bienvenue sur trouve ton vélo</h1>
        <h2>le site pour trouver ton vélo en libre service dans une station</h2>

        <v-row class="rowMenu" justify="center">
          <v-btn to="./map" color="#71afb7	" elevation="6" small>Rechercher un Vélo</v-btn>

         
        </v-row>
        <v-row class="rowMenu add-button" justify="center">
          <v-btn class="" >Installer Appli</v-btn> 
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  mounted(){
    let deferredPrompt;
    const addBtn = document.querySelector('.add-button');
    addBtn.style.display = 'none';
    window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  addBtn.style.display = 'block';

  addBtn.addEventListener('click', (e) => {
    // hide our user interface that shows our A2HS button
    addBtn.style.display = 'none';
    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        deferredPrompt = null;
      });
  });
});}
};
</script>
<style scoped>
:root {
  --main-text-color:white;
}



.v-btn {
  color:var(--main-text-color)!important;
  margin: 5px;
}
.rowMenu {
  padding: 2px;
}

.backgroundHome .row {
  margin: 0px;
  
  
}


.backgroundHome {
  background: url("../assets/rusty-bike-parked-on-boardwalk.jpg");
  height: 100%;
  text-align: center;
  color: var(--main-text-color)
}
@media screen and (max-width: 570px) {
  .backgroundHome {
    background: url("../assets/veloHome.mobile.png");
    position: relative;
    background-size: cover;
    height: 100%;
    text-align: center;
    color: white

  }
  /* .backgroundHome .opacity {
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    background-color: turquoise;
    opacity: 0.1;
  } */
  .main {
  margin: 0px;
  
      position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px; 
}
  .main .col {
       background-color: rgba(88, 86, 51,0.5);
  }

  .main h1{
        padding: 26px;
  }
  .main h2{
        padding: 26px;
  }
}
</style>