import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import x5GMaps from 'x5-gmaps';

Vue.config.productionTip = false
// Option 1: Just your key
//Vue.use(x5GMaps, 'AIzaSyDpuFetv6IKNwoPt7kz--mVOsv4YIbGajs')
// Option 2: With libraries
Vue.use(x5GMaps, { key: 'AIzaSyDpuFetv6IKNwoPt7kz--mVOsv4YIbGajs', libraries: ['places'] },)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
